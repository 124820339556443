import { parse } from "@config/theme";
export const prefooter = parse({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center"
}, "pik1wf5");
export const prefooterBorders = "p10l7yqp";
export const content = parse({
  py: "64",
  display: "grid",
  alignItems: "flex-start"
}, "cnmrjqu");
export const image = parse({
  display: "flex"
}, "iskx9qd");
export const imageFlexParent = parse({
  display: "flex",
  justifyContent: "center"
});
export const links = parse({
  display: "grid"
}, "lvl5xjh");

require("./styles.linaria.module.css");