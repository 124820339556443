import { parseAll, Theme } from "@config/theme"
import { cn } from "@lib/cn"
import React, { forwardRef, HTMLAttributes } from "react"
import { ThemeSystemProps } from "theme-system"

export type ContainerSize = "small" | "medium" | "big"

type ContainerProps = Pick<ThemeSystemProps<Theme>, "mb" | "mt"> &
  HTMLAttributes<HTMLDivElement> & {
    as?: "div" | "section"
    size?: ContainerSize
  }

export const Container = forwardRef<ContainerProps, any>((props, ref) => {
  const { mb, mt, className, as = "section", size = "medium", ...rest } = props
  const Comp = as
  return (
    <Comp
      className={cn(
        "relative mx-auto w-[100%-2.5rem]",
        size === "small" && "max-w-[728px]",
        size === "medium" && "max-w-[1088px]",
        size === "big" && "max-w-[1312px]",
        parseAll({ mb, mt }),
        className,
      )}
      data-size={size}
      {...rest}
      ref={ref}
    ></Comp>
  )
})

Container.displayName = "Container"
