import { parseAll, Theme } from "@config/theme"
import { cn } from "@lib/cn"
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  HTMLAttributes,
} from "react"
import { ThemeSystemProps } from "theme-system"
import IconChevronRightFat from "../icons/IconChevronRightFat"
import IconExternalFat from "../icons/IconExternalFat"
import { Heading, HeadingProps } from "../typograhpy/Heading"

type DefaultProps = {
  size: HeadingProps["variant"]
  color: "gradient" | "grey"
  variant: "internal" | "external"
}

type LinkVariant = DefaultProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "color"> & {
    as?: "link"
  }

type ButtonVariant = DefaultProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color"> & {
    as?: "button"
  }
type SpanVariant = DefaultProps &
  Omit<HTMLAttributes<HTMLSpanElement>, "color"> & {
    as?: "span"
  }

type HeadingLinkType = LinkVariant | ButtonVariant | SpanVariant

type HeadingLinkProps = Pick<ThemeSystemProps<Theme>, "mb" | "mt"> &
  HeadingLinkType

export const HeadingLink = forwardRef<any, HeadingLinkProps>((props, ref) => {
  const {
    as = "link",
    className,
    mb,
    mt,
    size,
    variant,
    color,
    children,
    ...rest
  } = props
  const iconSize = getIconSize(size)
  const Component = as === "link" ? "a" : as === "span" ? "span" : "button"
  return (
    // @ts-ignore
    <Component
      className={cn(
        "group flex items-center font-semibold text-shade500 opacity-100 transition-all duration-150",
        "active:scale-[0.99] active:before:opacity-0 enabled:hover:opacity-90",
        "focus-visible:ring-3 focus-visible:rounded-[3px] focus-visible:outline-none focus-visible:ring-shade300/50 disabled:cursor-not-allowed disabled:text-shade500/50",
        color === "gradient" && "text-purple",
        color === "grey" && "text-grey",
        className,
        parseAll({ mb, mt }),
      )}
      ref={ref}
      data-size={size}
      data-color={color}
      data-variant={variant}
      {...rest}
    >
      <Heading
        as="span"
        variant={size}
        data-text
        color="inherit"
        className={cn(
          color === "gradient" &&
            "relative bg-default bg-clip-text text-transparent",
        )}
      >
        {children}
      </Heading>
      <div
        className={cn(
          "ml-1 mt-1 flex items-center transition-transform duration-150",
          // @ts-ignore
          !rest.disabled &&
            cn(
              variant === "external" &&
                "group-hover:translate-x-[0.2rem] group-hover:translate-y-[-0.2rem]",
              variant === "internal" && "group-hover:translate-x-1",
            ),
        )}
        data-icon
      >
        {variant === "external" ? (
          <IconExternalFat size={iconSize} />
        ) : (
          <IconChevronRightFat size={iconSize} />
        )}
      </div>
    </Component>
  )
})

function getIconSize(size: HeadingProps["variant"]): number {
  switch (size) {
    case "h1": {
      return 48
    }
    case "h2": {
      return 40
    }
    case "h3": {
      return 32
    }
    case "h4": {
      return 24
    }
    case "h5": {
      return 24
    }
    default:
      return 16
  }
}

HeadingLink.displayName = "HeadingLink"
