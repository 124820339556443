import { Logo } from "@components/Logo"
import { Container } from "@components/ui/Container"
import { HeadingLink } from "@components/ui/heading-link"
import { Heading } from "@components/ui/typograhpy/Heading"
import * as styles from "./styles"
import Link from "next/link"
import { FC } from "react"
import { FooterSingleton } from "__generated__/sanity"
// import { ContentPageFooterForm } from "./form"
import { ContentPageFooterLink } from "./Link"
import Image from "next/image"

type ContentPageFooterProps = FooterSingleton

export const ContentPageFooter: FC<ContentPageFooterProps> = ({
  linksFirstColumn,
  linksSecondColumn,
}) => {
  return (
    <footer>
      <div className={styles.prefooterBorders}>
        <Container size="medium" className={styles.prefooter} as="div">
          <Heading variant="h1" as="h2" mb="0">
            Piqued your interest?
          </Heading>
          <Link href="/app" passHref legacyBehavior>
            <HeadingLink color="gradient" variant="internal" size="h1">
              Try for free
            </HeadingLink>
          </Link>
        </Container>
      </div>
      <div className={styles.imageFlexParent}>
        <div className={styles.image}>
          <Image
            src="/assets/swiss-flag.png"
            alt="Swiss flag"
            width={17}
            height={17}
          />
        </div>
      </div>
      <Container size="medium" as="div" className={styles.content}>
        <Logo variant="icon-only" />
        <div className={styles.links}>
          <div>
            {linksFirstColumn.map((link) => (
              <ContentPageFooterLink {...link} key={link._key} />
            ))}
          </div>
          <div>
            {linksSecondColumn.map((link) => (
              <ContentPageFooterLink {...link} key={link._key} />
            ))}
          </div>
        </div>
        {/* <ContentPageFooterForm /> */}
      </Container>
    </footer>
  )
}
