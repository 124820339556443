import { Button } from "@components/ui/button"
import { CleanIconButton } from "@components/ui/button/CleanIconButton"
import IconMenu from "@components/ui/icons/IconMenu"
import { Overlay } from "@components/ui/overlay"
import { Span } from "@components/ui/typograhpy/Span"
import Link from "next/link"
import { FC } from "react"
import { internalLinks } from "./index"
import * as styles from "./MobileMenu.styles"

export const ContentPageHeaderMobileMenu: FC = () => {
  return (
    <div className={styles.hamburger}>
      <Overlay
        trigger={
          <CleanIconButton label="menu" className="ml-0">
            <IconMenu />
          </CleanIconButton>
        }
        title="Menu"
      >
        <Span
          variant="button-label-big"
          color="white"
          className={styles.mobileMenuItem}
          href="https://docs.viesus.cloud"
          target="blank"
          as="a"
        >
          Docs
        </Span>
        {internalLinks.map((link) => (
          <Link href={link.href} passHref key={link.href} legacyBehavior>
            <Span
              variant="button-label-big"
              className={styles.mobileMenuItem}
              as="a"
            >
              {link.title}
            </Span>
          </Link>
        ))}
        <div className={styles.mobileButtons}>
          <Link href="/login" passHref legacyBehavior>
            <Span as="a" variant="button-label-small">
              Login
            </Span>
          </Link>
          <Link href="/signup/create-account" passHref legacyBehavior>
            <Button as="link" variant="primary" size="small" width="fill">
              Try for free
            </Button>
          </Link>
        </div>
      </Overlay>
    </div>
  )
}
