import { parse } from "@config/theme";
export const hamburger = parse({
  display: {
    _: "block",
    large: "none"
  }
});
export const mobileMenuItem = parse({
  display: "flex",
  width: "100%",
  textAlign: "left",
  alignItems: "center",
  px: "20"
}, "m1mzztlv");
export const mobileButtons = parse({
  p: "20",
  mt: "40",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}, "m1au1acv");

require("./MobileMenu.styles.linaria.module.css");